import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import {
  Text,
  View,
  ImageBackground,
  Pressable,
  Linking,
  FlatList,
} from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { capitalizeLetter, formatCurrency } from '@web-utils';
import { CreateResponsiveStyle, isWeb } from '~global-screens';
import { Fonts, Colors, ms, vs, hs } from '@web-styles';
import {
  Spacer,
  RatingStar,
  SliderControler,
  PaginationDot,
} from '@web-components';
import { LoaderOverlay } from '~global-components';

import {
  selectArticleState,
  selectArticleFetchStatus,
} from '~store/article/articleSlice';
import { getProductSlider } from '~store/article/articleThunk';

const customHTMLElementModels = {
  'product-slider': HTMLElementModel.fromCustomModel({
    tagName: 'product-slider',
    contentModel: HTMLContentModel.block,
  }),
};

const parseProdData = (i, prdctArray) => {
  const regex = /ids="([^"]*)"/;
  const match = prdctArray.match(regex);
  const idsString = match[1];
  const idsArray = idsString.split(',');
  let parsed = '';
  if (idsArray && idsArray.length) {
    parsed = `<product-slider id=${idsArray} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/["*+?^${}()|[\]\\]/gim, '\\$&')),
      parseProdData(i, listData[i]),
    );
  }
  return parsedContent;
};

const Product = ({ tnode }) => {
  const listRef = useRef(FlatList);
  const styles = componentStyles();
  const dispatch = useDispatch();
  const productSliderData = useSelector(selectArticleState('productData'));
  const isLoading = useSelector(
    selectArticleFetchStatus('getProductSliderFetch'),
  );
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const productIds = useMemo(() => {
    return tnode.id.split(',');
  }, [tnode]);

  useEffect(() => {
    const fetchDataProduct = async () => {
      if (productIds) {
        dispatch(getProductSlider(productIds));
      }
    };
    fetchDataProduct();
  }, []);

  const productSliderDataFiltered = productSliderData.filter((product) =>
    productIds.map((id) => parseInt(id)).includes(product.id),
  );

  const handleLinking = useCallback((url) => {
    Linking.openURL(url);
  }, []);

  const handleChangeIndex = useCallback(
    (type) => {
      if (type === 'next' && activeIndex < productSliderDataFiltered.length) {
        setActiveIndex((prev) => prev + 1);
        listRef.current.scrollToIndex({ animated: true, index: activeIndex });
      } else if (type === 'prev' && activeIndex > 1) {
        setActiveIndex((prev) => prev - 1);
        listRef.current.scrollToIndex({
          animated: true,
          index: activeIndex - 2,
        });
      }
    },
    [activeIndex, productSliderDataFiltered.length],
  );

  const _renderItem = useCallback(
    ({ item }) => {
      if (item === undefined) {
        return null;
      }

      const isDiscount =
        item?.discount_percentage && item?.discount_percentage !== '';
      const isActive = item.is_active_in_sociolla || item.is_active_in_lulla;
      const url = isActive
        ? item.is_active_in_sociolla
          ? item.url_sociolla
          : item.url_lulla
        : '';
      const component = (
        <>
          <View style={styles('imageContainer')}>
            <View style={styles('productImgWrapper')}>
              <View style={styles('productImgContainer')}>
                {isDiscount && (
                  <View style={styles('discountContainer')}>
                    <Text style={styles('discountText')}>
                      {item.discount_percentage}%
                    </Text>
                  </View>
                )}
                <ImageBackground
                  source={item?.images?.[0]?.url}
                  style={styles('productImg')}
                  role="presentation"
                />
              </View>
            </View>
          </View>
          <View style={styles('detailContainer')}>
            <Text style={Fonts('titleProductSlider')}>
              {capitalizeLetter(item?.brand.name)}
            </Text>
            <Spacer height={isWeb ? 10 : 5} />
            <Text style={Fonts('descProductSlider')}>{item?.name}</Text>
            <Spacer height={5} />
            <View style={styles('priceContainer')}>
              <Text
                style={[
                  isDiscount ? Fonts('priceRegular') : Fonts('priceBold'),
                  {
                    ...(isDiscount && {
                      textDecorationLine: 'line-through',
                    }),
                  },
                ]}>
                {item?.min_price === item?.max_price
                  ? `Rp ${formatCurrency(item?.min_price)}`
                  : `Rp ${formatCurrency(
                      item?.min_price,
                    )} - Rp ${formatCurrency(item?.max_price)}`}
              </Text>
              {isDiscount && (
                <Text
                  style={[
                    Fonts('priceRegular'),
                    { color: Colors.VENETIAN_RED },
                  ]}>
                  {item?.min_price_after_discount ===
                  item?.max_price_after_discount
                    ? `Rp ${formatCurrency(item?.min_price_after_discount)}`
                    : `Rp ${formatCurrency(
                        item?.min_price_after_discount,
                      )} - Rp ${formatCurrency(
                        item?.max_price_after_discount,
                      )}`}
                </Text>
              )}
            </View>
            <View style={styles('priceContainer')}></View>
            <Text style={styles('discountAmountText')}>
              {item?.discount_range}
            </Text>
            <Spacer height={5} />
            <RatingStar
              maxRating={5}
              rating={item?.review_stats.average_rating}
              count={item?.review_stats.total_reviews}
              starWidth={13}
              starHeight={12}
            />
            <Spacer height={12} />
            {isActive ? (
              <Pressable
                onPress={() => {
                  handleLinking(url);
                }}
                role="button"
                style={styles('shopBtn')}>
                <Text
                  style={[
                    Fonts('titleProductSlider'),
                    { color: Colors.WHITE },
                  ]}>
                  SHOP
                </Text>
              </Pressable>
            ) : (
              <></>
            )}
          </View>
        </>
      );

      return isActive ? (
        <Pressable
          onPress={() => handleLinking(url)}
          style={[styles('container'), { width: width }]}>
          {component}
        </Pressable>
      ) : (
        <View style={[styles('container'), { width: width }]}>{component}</View>
      );
    },
    [productSliderData, width],
  );

  if (isLoading) {
    return <LoaderOverlay show={isLoading} single />;
  }

  if (productSliderDataFiltered === null) {
    return null;
  }

  return (
    <View
      style={styles('mainContainer')}
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width);
      }}>
      {productSliderDataFiltered?.length > 1 && (
        <SliderControler
          handleChangeIndex={handleChangeIndex}
          activeIndex={activeIndex}
          data={productSliderDataFiltered}
        />
      )}
      <View>
        <FlatList
          ref={listRef}
          data={productSliderDataFiltered}
          renderItem={_renderItem}
          keyExtractor={(item, index) => `${item?._id}-${index}`}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          initialScrollIndex={0}
          getItemLayout={(data, index) => ({
            length: width,
            offset: width * index,
            index,
          })}
          onMomentumScrollEnd={(event) => {
            const newIndex =
              Math.round(event.nativeEvent.contentOffset.x / width) + 1;
            setActiveIndex(newIndex);
          }}
        />
      </View>
      {productSliderDataFiltered?.length > 1 && (
        <PaginationDot
          data={productSliderDataFiltered}
          activeIndex={activeIndex}
        />
      )}
    </View>
  );
};

const webStyles = {
  mainContainer: {
    justifycontent: 'center',
    flex: 1,
  },
  container: {
    padding: 16,
    borderWidth: 1,
    borderRadius: 4,
    flexDirection: 'row',
    columnGap: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  productImgContainer: {
    position: 'relative',
    width: 241,
    height: 241,
  },
  discountContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 60,
    height: 60,
    borderRadius: 30,
    zIndex: 1,
    backgroundColor: Colors.VENETIAN_RED,
    alignItems: 'center',
    justifyContent: 'center',
  },
  discountText: {
    fontFamily: 'brandon-bold',
    fontSize: 14,
    color: Colors.WHITE,
  },
  productImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  imageContainer: {
    flex: 1,
    alignItems: 'start',
  },
  detailContainer: {
    flex: 2,
  },
  shopBtn: {
    backgroundColor: Colors.VENETIAN_RED,
    width: 136,
    height: 36,
    padding: 12,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceContainer: {
    flexDirection: 'row',
    columnGap: 8,
  },
  sliderControlContainer: {
    alignItems: 'center',
  },
  sliderControl: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 20,
    width: '70%',
  },
  btnContainer: {
    flexDirection: 'row',
    columnGap: 16,
    alignItems: 'center',
  },
  arrowContainer: {
    width: 30,
    height: 30,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const mobileStyles = {
  discountContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  discountText: {
    fontSize: ms(12),
  },
  productImgContainer: {
    position: 'relative',
    width: 120,
    height: 120,
  },
  productImg: {
    width: 120,
    height: 120,
  },
  imageContainer: {
    flex: 1.5,
  },
  detailContainer: {
    flex: 2,
  },
  shopBtn: {
    width: 118,
    height: 24,
  },
  sliderControl: {
    marginTop: vs(16),
    marginBottom: vs(20),
  },
  arrowContainer: {
    width: hs(25),
    height: vs(25),
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'product-slider': Product,
};

Product.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
