import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import { screenWidth } from '~global-screens';
import { ScreenLayout } from '@web-styles';

/**
 * @author Chandra Santoso <chandra.santoso@sociolla.com>
 * @name Spacer
 * @param {width} - Size width between component
 * @param {height} - Size height between component
 * @param {divider} - fullwidth divider
 * @param {color} - color divider
 *
 * @example
 * <Spacer width={20}/>
 * <Spacer height={20}/>
 */

const Spacer = ({ width, height, divider, color }) => {
  if (width) {
    return <View style={{ width }} />;
  }

  if (height) {
    return <View style={{ height }} />;
  }

  if (divider) {
    const dividerStyle = {
      width: width ?? screenWidth,
      height: 1,
      backgroundColor: color ?? 'black',
    };
    return <View style={dividerStyle} />;
  }

  return <View style={ScreenLayout.flex} />;
};

Spacer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  divider: PropTypes.bool,
  color: PropTypes.string,
};

export default Spacer;
