import axios from 'axios';
const Config = require('~config/default.env').default;

const PUBLIC_URL = `${Config.MS_BJ_PUBLIC_API_URL}`;

export const API_BANNER_BJ = async (payload) => {
  try {
    return await axios.get(`${PUBLIC_URL}/banners`, payload);
  } catch (error) {
    console.error('API_BANNER_BJ error', error);
    throw error;
  }
};

export const API_TRENDING_TAG_BJ = async (payload) => {
  try {
    return await axios.get(`${PUBLIC_URL}/tags`, payload);
  } catch (error) {
    console.error('API_TRENDING_TAG_BJ error', error);
    throw error;
  }
};

export const API_SEARCH_BJ_COUNT = async (payload) => {
  try {
    return await axios.get(`${PUBLIC_URL}/search/count`, payload);
  } catch (error) {
    console.error('API_SEARCH_BJ error', error);
    throw error;
  }
};

export const API_SEARCH_BJ = async (payload, url) => {
  try {
    return await axios.get(`${PUBLIC_URL}/search${url ?? ''}`, payload);
  } catch (error) {
    console.error('API_SEARCH_BJ error', error);
    throw error;
  }
};

export const API_AGENDA_BJ = async (payload) => {
  try {
    return await axios.get(`${PUBLIC_URL}/agendas`, payload);
  } catch (error) {
    console.error('API_AGENDA_BJ error', error);
    throw error;
  }
};

export const API_POST_BJ = async (payload, url, method) => {
  try {
    return await axios[method](`${PUBLIC_URL}/post${url ?? ''}`, payload);
  } catch (error) {
    console.error('API_POST_BJ error', error);
    throw error;
  }
};

export const API_CATEGORY_BJ = async ({ payload, url, method, directUrl }) => {
  try {
    return await axios[method](
      directUrl ?? `${PUBLIC_URL}/${url ?? ''}`,
      payload,
    );
  } catch (error) {
    console.error('API_CATEGORY_BJ error', error);
    throw error;
  }
};

export const API_QUIZ_BJ = async (payload, url) => {
  try {
    return await axios.post(`${PUBLIC_URL}/quiz${url ?? ''}`, payload);
  } catch (error) {
    console.error('API_QUIZ_BJ error', error);
    throw error;
  }
};

export const API_POSTS_BJ = async ({ payload, url, method, directUrl }) => {
  try {
    return await axios[method](
      directUrl ?? `${PUBLIC_URL}/posts${url ?? ''}`,
      payload,
    );
  } catch (error) {
    console.error('API_POSTS_BJ error', error);
    throw error;
  }
};

export const API_GLOSSARY_BJ = async (directUrl) => {
  try {
    return await axios.get(directUrl);
  } catch (error) {
    console.error('API_GLOSSARY_BJ error', error);
    throw error;
  }
};
