import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_POSTS_BJ, API_BANNER_BJ } from '~global-api/bj.api';
import { API_USER_SOCO } from '~global-api/soco.api';
import dayjs from 'dayjs';
import { API_CATEGORY_BJ } from '../../global/api/bj.api';

export const getBanners = createAsyncThunk('app/getBanners', async () => {
  const response = await API_BANNER_BJ();
  return response.data;
});

export const getArticleCategoryIds = createAsyncThunk(
  'app/getArticleCategoryIds',
  async () => {
    const response = await API_CATEGORY_BJ({
      method: 'get',
      url: '/post-categories',
      payload: {
        params: {
          filter: {
            slug: {
              $in: ['whats-good', 'whats-new', 'whats-fun'],
            },
          },
          fields: '_id slug',
        },
      },
    });

    return { data: response.data };
  },
);

export const getArticleMegaMenu = createAsyncThunk(
  'app/getArticleMegaMenu',
  async ({ categoryId, slug }) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/random',
      payload: {
        params: {
          filter: {
            post_type: 'article',
            'category.id': categoryId,
            published_at: {
              $gte: new Date(dayjs().subtract(1, 'week')),
            },
          },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views',
          sort: '-total_views',
          limit: 2,
        },
      },
    });
    return { data: response.data, type: slug };
  },
);

export const getUserStatus = createAsyncThunk('app/getUserStatus', async () => {
  const response = await API_USER_SOCO();
  return response.data;
});
