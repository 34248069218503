import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { WebView } from 'react-native-webview';
import { useFocusEffect } from '@react-navigation/native';

import { CreateResponsiveStyle } from '~global-screens';

const customHTMLElementModels = {
  'instagram-frame': HTMLElementModel.fromCustomModel({
    tagName: 'instagram-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const parseInstagramData = (i, igLink) => {
  const url = igLink;
  let parsed = '';
  if (url && url.length) {
    parsed = `<instagram-frame id=${igLink} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseInstagramData(i, listData[i]),
    );
  }
  return parsedContent;
};

const InstagramFrame = ({ tnode }) => {
  const webViewRef = useRef();
  const styles = componentStyles();
  const htmlStr = `<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="${tnode.id}" data-instgrm-version="14"><a href=${tnode.id}></a></blockquote><script async defer src="https://platform.instagram.com/en_US/embeds.js"></script>`;
  const [contentHeight, setContentHeight] = useState(800);

  const findContentHeight = () => {
    setTimeout(() => {
      const iframeHeight =
        webViewRef.current.frameRef.contentDocument.querySelector(
          'iframe',
        ).height;
      if (iframeHeight && Number(iframeHeight) !== 0) {
        setContentHeight(Number(iframeHeight) + 30);
      }
    }, 2000);
  };

  useFocusEffect(
    React.useCallback(() => {
      findContentHeight();
      return () => {
        clearTimeout();
      };
    }, []),
  );

  return (
    <View>
      <WebView
        ref={webViewRef}
        source={{ html: htmlStr, baseUrl: 'https://www.instagram.com' }}
        style={[styles('containerWebView'), { height: contentHeight }]}
        scalesPageToFit={true}
        automaticallyAdjustContentInsets={false}
      />
    </View>
  );
};

const webStyles = {
  containerWebView: {
    width: 358,
    alignSelf: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'instagram-frame': InstagramFrame,
};

InstagramFrame.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
