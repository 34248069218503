import React from 'react';
import { View, FlatList, Text } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { factRegex } from './Regex.constant';
import { CreateResponsiveStyle } from '~global-screens';
import { Colors } from '@web-styles';

import { selectArticleState } from '~store/article/articleSlice';

const customHTMLElementModels = {
  'fact-slider': HTMLElementModel.fromCustomModel({
    tagName: 'fact-slider',
    contentModel: HTMLContentModel.block,
  }),
};

const parseFactData = (i, factArray, handleSetFactData) => {
  let parsed;
  const convertToArrayObject = [...factArray.matchAll(factRegex)];
  const result = convertToArrayObject.map((match) => ({
    id: i,
    title: match[1],
    text: match[2],
  }));
  handleSetFactData(result);
  if (result && result?.length) {
    parsed = `<fact-slider  id=${i}   />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent, handleSetFactData) => {
  let parsedContent = htmlContent;
  if (listData?.length > 0) {
    for (let i = 0; i < listData?.length; i++) {
      parsedContent = parsedContent.replace(
        new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
        parseFactData(i, listData[i], handleSetFactData),
      );
    }
  }
  return parsedContent;
};

const FactSlider = ({ tnode }) => {
  const styles = componentStyles();
  const factData = useSelector(selectArticleState('factData'));

  const _renderItem = ({ item }) => {
    return (
      <View style={styles('itemContainer')}>
        <View style={styles('itemFlex')}>
          <Text numberOfLines={3} style={styles('titleFonts')}>
            {item.title}
          </Text>
        </View>
        <View style={styles('itemFlex')}>
          <Text numberOfLines={4} style={styles('textFonts')}>
            {item.text}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles('container')}>
      <Text style={styles('titleFonts')}>Facts</Text>
      <FlatList
        data={factData?.filter((e) => e.id === Number(tnode.id))}
        horizontal
        keyExtractor={(item, index) => `list-fact-${item?.title}-${index}`}
        renderItem={_renderItem}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles('contentContainerStyle')}
      />
    </View>
  );
};

FactSlider.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const webStyles = {
  container: {
    marginVertical: 16,
    rowGap: 16,
  },
  titleFonts: {
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '38px',
    letterSpacing: 0.6,
  },
  textFonts: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0.3,
  },
  itemContainer: {
    height: 267,
    width: 267,
    borderWidth: 1,
    backgroundColor: Colors.WHITE,
    padding: 16,
  },
  itemFlex: {
    flex: 1,
  },
  contentContainerStyle: {
    columnGap: 30,
  },
};

const mobileStyles = {
  container: {
    marginVertical: 10,
    rowGap: 10,
    // backgroundColor: Colors.SPRING_BUD,
    padding: 16,
  },
  titleFonts: {
    fontSize: 26,
    lineHeight: '30px',
    letterSpacing: 0,
  },
  textFonts: {
    fontSize: 13,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  itemContainer: {
    width: 181,
    height: 214,
    paddingVertical: 8,
  },
  contentContainerStyle: {
    columnGap: 16,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'fact-slider': FactSlider,
};

export default { renderers, customHTMLElementModels, parseHtmlData };
