/* eslint-disable react-native/no-color-literals */
import React from 'react';
import { StyleSheet } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { isWeb } from '~global-screens';

import { compareSliderRegex } from './Regex.constant';

const customHTMLElementModels = {
  'comparison-slide': HTMLElementModel.fromCustomModel({
    tagName: 'comparison-slide',
    contentModel: HTMLContentModel.block,
  }),
};

const parseHtmlData = (htmlContent) => {
  let parsedContent = htmlContent;

  parsedContent = parsedContent.replace(compareSliderRegex, (match) => {
    const imgMatch = match.match(/img="([^"]*)"/);
    if (imgMatch && imgMatch[1]) {
      const [firstImage, secondImage] = imgMatch[1].split(',');
      return `<comparison-slide firstimage="${firstImage.trim()}" secondimage="${secondImage.trim()}" />`;
    }
    return match;
  });

  return parsedContent;
};

const ComparisonSlide = ({ tnode }) => {
  const containerStyle = {
    maxWidth: isWeb ? '70%' : '100%',
    maxHeight: isWeb ? 450 : undefined,
    alignSelf: 'center',
    '--divider-color': '#0f172a',
    '--divider-width': '4px',
  };

  return (
    <ImgComparisonSlider style={containerStyle}>
      <img
        slot="first"
        src={tnode.attributes.firstimage}
        alt="First"
        style={styles.firstImage}
      />
      <img
        slot="second"
        src={tnode.attributes.secondimage}
        alt="Second"
        style={styles.secondImage}
      />
      <div slot="handle" style={styles.customHandle}>
        <div style={styles.handleArrow}>&#10094;</div>
        <div style={styles.handleArrow}>&#10095;</div>
      </div>
    </ImgComparisonSlider>
  );
};

const styles = StyleSheet.create({
  customHandle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    gap: 2,
    justifyContent: 'center',
    alignItems: 'center',
    // Box shadow in React Native
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  handleArrow: {
    color: '#0F172A',
    fontSize: 14,
    marginLeft: 2, // Use individual margins
    marginRight: 2,
  },
  firstImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  secondImage: {
    maxWidth: '100%',
    maxHeight: isWeb ? 450 : undefined,
  },
});

const renderers = {
  'comparison-slide': ComparisonSlide,
};

ComparisonSlide.propTypes = {
  tnode: PropTypes.shape({
    attributes: PropTypes.shape({
      firstimage: PropTypes.string.isRequired,
      secondimage: PropTypes.string.isRequired,
    }),
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
