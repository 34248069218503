import { createSlice } from '@reduxjs/toolkit';
import {
  getTrendingTag,
  getProductFeatured,
  getSearchTotalCount,
  getSearchData,
  getTagTotalCount,
  getTagResultData,
  getSubCatCount,
  getSubCatResultData,
  getQuizCount,
  getQuizResultData,
  getTrendingTopics,
  getCatCount,
  getCatResultData,
} from './searchThunk';
import { removeDuplicateArray } from '@web-utils';

const initialState = {
  status: {
    getTrendingTagFetch: false,
    getTrendingTagError: null,
    getProductFeaturedFetch: false,
    getProductFeaturedError: null,
    getSearchDataFetch: false,
    getSearchDataError: null,
    getTrendingTopicsFetch: false,
    getTrendingTopicsError: null,
  },
  trendingTag: [],
  productFeatured: [],
  trendingTopics: [],
  searchResult: {
    totalCount: 0,
    articleCount: 0,
    quizCount: 0,
    shortCount: 0,
    glossaryCount: 0,
    totalResult: [],
    articleResult: [],
    quizResult: [],
    shortResult: [],
    glossaryResult: [],
  },
};

const { reducer, actions } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearchResult(state) {
      state.searchResult = {
        totalCount: 0,
        articleCount: 0,
        quizCount: 0,
        shortCount: 0,
        glossaryCount: 0,
        totalResult: [],
        articleResult: [],
        quizResult: [],
        shortResult: [],
        glossaryResult: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrendingTag.pending, (state) => {
        state.status.getTrendingTagError = null;
        state.status.getTrendingTagFetch = true;
      })
      .addCase(getTrendingTag.rejected, (state, action) => {
        state.status.getTrendingTagFetch = false;
        state.status.getTrendingTagError = action?.error?.message;
      })
      .addCase(getTrendingTag.fulfilled, (state, { payload }) => {
        state.status.getTrendingTagFetch = false;
        state.trendingTag = payload.data;
      })
      .addCase(getProductFeatured.pending, (state) => {
        state.status.getProductFeaturedError = null;
        state.status.getProductFeaturedFetch = true;
      })
      .addCase(getProductFeatured.rejected, (state, action) => {
        state.status.getProductFeaturedFetch = false;
        state.status.getProductFeaturedError = action?.error?.message;
      })
      .addCase(getProductFeatured.fulfilled, (state, { payload }) => {
        state.status.getProductFeaturedFetch = false;
        const res = payload.data.slice(0, 3);
        state.productFeatured = res;
      })
      .addCase(getSearchData.pending, (state) => {
        state.status.getSearchDataError = null;
        state.status.getSearchDataFetch = true;
      })
      .addCase(getSearchData.rejected, (state, action) => {
        state.status.getSearchDataFetch = false;
        state.status.getSearchDataError = action?.error?.message;
      })
      .addCase(getSearchData.fulfilled, (state, { payload }) => {
        const updateSearchResult = (state, payload) => {
          const typeMap = {
            article: 'articleResult',
            pid_quiz: 'quizResult',
            bjtv: 'shortResult',
            bjglossary: 'glossaryResult',
          };
          const resultType = typeMap[payload.type] || 'totalResult';
          state.searchResult[resultType] = removeDuplicateArray(
            state.searchResult[resultType].concat(payload.data.data),
            '_id',
          );
        };
        updateSearchResult(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getSearchTotalCount.fulfilled, (state, { payload }) => {
        const updateCount = (state, payload) => {
          const countMap = {
            article: 'articleCount',
            pid_quiz: 'quizCount',
            bjtv: 'shortCount',
            bjglossary: 'glossaryCount',
          };
          const countType = countMap[payload.type] || 'totalCount';
          state.searchResult[countType] = payload.count;
        };
        updateCount(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getTagTotalCount.fulfilled, (state, { payload }) => {
        const updateCount = (state, payload) => {
          const countMap = {
            article: 'articleCount',
            pid_quiz: 'quizCount',
            bjtv: 'shortCount',
            bjglossary: 'glossaryCount',
          };
          const countType = countMap[payload.type] || 'totalCount';
          state.searchResult[countType] = payload.count;
        };
        updateCount(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getTagResultData.pending, (state) => {
        state.status.getSearchDataError = null;
        state.status.getSearchDataFetch = true;
      })
      .addCase(getTagResultData.rejected, (state, action) => {
        state.status.getSearchDataFetch = false;
        state.status.getSearchDataError = action?.error?.message;
      })
      .addCase(getTagResultData.fulfilled, (state, { payload }) => {
        const updateSearchResult = (state, payload) => {
          const typeMap = {
            article: 'articleResult',
            pid_quiz: 'quizResult',
            bjtv: 'shortResult',
            bjglossary: 'glossaryResult',
          };
          const resultType = typeMap[payload.type] || 'totalResult';
          state.searchResult[resultType] = removeDuplicateArray(
            state.searchResult[resultType].concat(payload.data.data),
            '_id',
          );
        };
        updateSearchResult(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getSubCatCount.fulfilled, (state, { payload }) => {
        const updateCount = (state, payload) => {
          const countMap = {
            article: 'articleCount',
            pid_quiz: 'quizCount',
            bjtv: 'shortCount',
            bjglossary: 'glossaryCount',
          };
          const countType = countMap[payload.type] || 'totalCount';
          state.searchResult[countType] = payload.count;
        };
        updateCount(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getSubCatResultData.pending, (state) => {
        state.status.getSearchDataError = null;
        state.status.getSearchDataFetch = true;
      })
      .addCase(getSubCatResultData.rejected, (state, action) => {
        state.status.getSearchDataFetch = false;
        state.status.getSearchDataError = action?.error?.message;
      })
      .addCase(getSubCatResultData.fulfilled, (state, { payload }) => {
        const updateSearchResult = (state, payload) => {
          const typeMap = {
            article: 'articleResult',
            pid_quiz: 'quizResult',
            bjtv: 'shortResult',
            bjglossary: 'glossaryResult',
          };
          const resultType = typeMap[payload.type] || 'totalResult';
          state.searchResult[resultType] = removeDuplicateArray(
            state.searchResult[resultType].concat(payload.data.data),
            '_id',
          );
        };
        updateSearchResult(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getQuizResultData.pending, (state) => {
        state.status.getSearchDataError = null;
        state.status.getSearchDataFetch = true;
      })
      .addCase(getQuizResultData.rejected, (state, action) => {
        state.status.getSearchDataFetch = false;
        state.status.getSearchDataError = action?.error?.message;
      })
      .addCase(getQuizResultData.fulfilled, (state, { payload }) => {
        const updateSearchResult = (state, payload) => {
          const typeMap = {
            article: 'articleResult',
            pid_quiz: 'quizResult',
            bjtv: 'shortResult',
            bjglossary: 'glossaryResult',
          };
          const resultType = typeMap[payload.type] || 'totalResult';
          state.searchResult[resultType] = removeDuplicateArray(
            state.searchResult[resultType].concat(payload.data.data),
            '_id',
          );
        };
        updateSearchResult(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getQuizCount.fulfilled, (state, { payload }) => {
        const updateCount = (state, payload) => {
          const countMap = {
            article: 'articleCount',
            pid_quiz: 'quizCount',
            bjtv: 'shortCount',
            bjglossary: 'glossaryCount',
          };
          const countType = countMap[payload.type] || 'totalCount';
          state.searchResult[countType] = payload.count;
        };
        updateCount(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getTrendingTopics.pending, (state) => {
        state.status.getTrendingTopicsError = null;
        state.status.getTrendingTopicsFetch = true;
      })
      .addCase(getTrendingTopics.rejected, (state, action) => {
        state.status.getTrendingTopicsFetch = false;
        state.status.getTrendingTopicsError = action?.error?.message;
      })
      .addCase(getTrendingTopics.fulfilled, (state, { payload }) => {
        state.status.getTrendingTopicsFetch = false;
        state.trendingTopics = payload.data;
      })
      .addCase(getCatCount.fulfilled, (state, { payload }) => {
        const updateCount = (state, payload) => {
          const countMap = {
            article: 'articleCount',
            pid_quiz: 'quizCount',
            bjtv: 'shortCount',
            bjglossary: 'glossaryCount',
          };
          const countType = countMap[payload.type] || 'totalCount';
          state.searchResult[countType] = payload.count;
        };
        updateCount(state, payload);
        state.status.getSearchDataFetch = false;
      })
      .addCase(getCatResultData.pending, (state) => {
        state.status.getSearchDataError = null;
        state.status.getSearchDataFetch = true;
      })
      .addCase(getCatResultData.rejected, (state, action) => {
        state.status.getSearchDataFetch = false;
        state.status.getSearchDataError = action?.error?.message;
      })
      .addCase(getCatResultData.fulfilled, (state, { payload }) => {
        const updateSearchResult = (state, payload) => {
          const typeMap = {
            article: 'articleResult',
            pid_quiz: 'quizResult',
            bjtv: 'shortResult',
            bjglossary: 'glossaryResult',
          };
          const resultType = typeMap[payload.type] || 'totalResult';
          state.searchResult[resultType] = removeDuplicateArray(
            state.searchResult[resultType].concat(payload.data.data),
            '_id',
          );
        };
        updateSearchResult(state, payload);
        state.status.getSearchDataFetch = false;
      });
  },
});

export const { resetSearchResult } = actions;

export const selectTrendingTag = (state) => state.search.trendingTag;
export const selectProductFeatured = (state) => state.search.trendingTag;
export const selectSearchFetchStatus = (type) => {
  return (state) => state.search.status[type];
};
export const selectSearchState = (type) => {
  return (state) => state.search[type];
};

export default reducer;
