import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import appReducer from './app/slice';
import authReducer from './auth/authSlice';
import searchReducer from './search/searchSlice';
import sectionReducer from './section/sectionSlice';
import articleReducer from './article/articleSlice';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  search: searchReducer,
  section: sectionReducer,
  article: articleReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: [thunk],
});
