import React from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import { WebView } from 'react-native-webview';
import PropTypes from 'prop-types';

import { CreateResponsiveStyle } from '~global-screens';
import { iframeSrcRegex } from './Regex.constant';

const customHTMLElementModels = {
  'spotify-frame': HTMLElementModel.fromCustomModel({
    tagName: 'spotify-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const parseSpotifyData = (i, spotifyLink) => {
  const url = spotifyLink;
  let parsed = '';
  if (url && url[0].includes('https://open.spotify.com/embed/track/')) {
    parsed = `<spotify-frame id=${url} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    const iframeSrc = listData[i].match(iframeSrcRegex);
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseSpotifyData(i, iframeSrc),
    );
  }
  return parsedContent;
};

const SpotifyFrame = ({ tnode }) => {
  const htmlStr = `<iframe src="${tnode.id}" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
  const styles = componentStyles();

  return (
    <View>
      <WebView
        source={{ html: htmlStr, baseUrl: 'https://open.spotify.com/' }}
        style={styles('containerWebView')}
        scalesPageToFit={true}
        automaticallyAdjustContentInsets={false}
        scrollEnabled={false}
      />
    </View>
  );
};

const webStyles = {
  containerWebView: {
    width: 320,
    height: 400,
    alignSelf: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'spotify-frame': SpotifyFrame,
};

SpotifyFrame.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
