/* eslint-disable react/prop-types */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text, StyleSheet, Pressable, Image } from 'react-native';
import { Colors } from '@web-styles';
import { AntDesign } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';
import { isWeb } from '~global-screens';

const CHECKED = require('~assets/images/checked.svg');
const EXCLAMATION = require('~assets/images/exclamation-mark.svg');

const BaseCustomToast = ({ type, text1, onPress, ...rest }) => {
  return (
    <View style={styles.container} {...rest}>
      <View
        style={[
          styles.iconContainer,
          {
            backgroundColor: type === 'success' ? '#A1F916' : '#D0021B',
          },
        ]}>
        <Image
          source={type === 'success' ? CHECKED : EXCLAMATION}
          style={{
            width: 26,
            height: 26,
            resizeMode: 'contain',
            tintColor: type === 'success' ? Colors.BLACK : Colors.WHITE,
          }}
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.text1Font}>{text1}</Text>
      </View>
      <Pressable
        onPress={() => {
          onPress();
          Toast.hide();
        }}
        style={styles.closeContainer}>
        <AntDesign name="close" size={20} color="black" />
      </Pressable>
    </View>
  );
};

export const toastConfig = {
  success: ({ text1, text2, onPress, ...rest }) => {
    return (
      <BaseCustomToast
        text1={text1}
        type={'success'}
        onPress={onPress}
        {...rest}
      />
    );
  },
  error: ({ text1, onPress, ...rest }) => {
    return (
      <BaseCustomToast
        text1={text1}
        type={'error'}
        onPress={onPress}
        {...rest}
      />
    );
  },
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    width: isWeb ? 550 : 358,
    zIndex: 999,
    backgroundColor: Colors.WHITE,
    flexDirection: 'row',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  iconContainer: {
    flex: isWeb ? 0.3 : 0.4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    flex: 3,
    padding: 12,
    justifyContent: 'center',
  },
  text1Font: {
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '18px',
  },
  closeContainer: {
    flex: isWeb ? 0.3 : 0.4,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
