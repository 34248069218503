import React, { useRef } from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { WebView } from 'react-native-webview';

import { CreateResponsiveStyle } from '~global-screens';

const customHTMLElementModels = {
  'twitter-frame': HTMLElementModel.fromCustomModel({
    tagName: 'twitter-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const replaceXWithTwitter = (url) => {
  if (url.startsWith('https://x.com/')) {
    return url.replace('https://x.com/', 'https://twitter.com/');
  }
  return url;
};

const parseTwitterData = (i, twtLink) => {
  const url = replaceXWithTwitter(twtLink);

  let parsed = '';
  if (url && url.length) {
    parsed = `<twitter-frame id=${url} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;

  for (let i = 0; i < listData.length; i++) {
    const processedUrl = replaceXWithTwitter(listData[i]);
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseTwitterData(i, processedUrl),
    );
  }

  return parsedContent;
};

const TwitterFrame = ({ tnode }) => {
  const twitRef = useRef();
  const styles = componentStyles();
  const htmlStr = `<blockquote class="twitter-tweet" data-lang="id">< href=${tnode.id}/><a href=${tnode.id}/>< href=${tnode.id}/></blockquote><script type="text/javascript" src="https://platform.twitter.com/widgets.js"></script>`;

  return (
    <View>
      <WebView
        ref={twitRef}
        source={{ html: htmlStr, baseUrl: 'https://www.twitter.com' }}
        style={styles('containerWebView')}
        scalesPageToFit={true}
        automaticallyAdjustContentInsets={false}
        javascriptenabled={true}
        scrollEnabled={false}
      />
    </View>
  );
};

const webStyles = {
  containerWebView: {
    width: 358,
    flex: 1,
    minHeight: 360,
    alignSelf: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'twitter-frame': TwitterFrame,
};

TwitterFrame.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
