import axios from 'axios';
const Config = require('~config/default.env').default;

export const API_AUTH_SOCO = async (method, url, payload) => {
  try {
    return await axios[method](
      `${Config.MS_SOCO_PUBLIC_API_URL}/auth${url ?? ''}`,
      payload,
    );
  } catch (error) {
    console.error('API_AUTH_SOCO error', error);
    throw error;
  }
};

export const API_PRODUCT_SOCO = async (payload, url) => {
  try {
    return await axios.get(
      `${Config.MS_SOCO_PUBLIC_API_URL}/products${url ?? ''}`,
      payload,
    );
  } catch (error) {
    console.error('API_PRODUCT_SOCO error', error);
    throw error;
  }
};

export const API_USER_SOCO = async () => {
  try {
    return await axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me`);
  } catch (error) {
    console.error('API_USER_SOCO error', error);
    throw error;
  }
};
