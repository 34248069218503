import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_PRODUCT_CATALOG,
  API_V3_PRODUCT_CATALOG,
} from '~global-api/catalog.api';
import { API_POSTS_BJ, API_POST_BJ, API_QUIZ_BJ } from '~global-api/bj.api';

export const getProductSlider = createAsyncThunk(
  'article/getProductSlider',
  async (ids) => {
    const response = await API_V3_PRODUCT_CATALOG(ids);
    return response;
  },
);

export const getArticleData = createAsyncThunk(
  'article/getArticleData',
  async (slug) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${slug}`,
      payload: {
        params: {
          section: 'articleDetail',
        },
      },
    });
    return response.data;
  },
);

export const getArticleHighlight = createAsyncThunk(
  'article/getArticleHighlight',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/highlight/the-highlights',
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views total_comments',
        },
      },
    });
    return response.data;
  },
);

export const getQuizData = createAsyncThunk(
  'article/getQuizData',
  async (slug) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${slug}`,
      payload: {
        params: {
          section: 'quizDetail',
        },
      },
    });
    return response.data;
  },
);

export const getRelatedPost = createAsyncThunk(
  'article/getRelatedPost',
  async (id) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            post_type: ['article', 'bjtv', 'pid_quiz', 'polling'],
            _id: id,
          },
          fields:
            '_id title slug owner summary category attachments post_type total_comments total_views published_at',
          limit: 6,
        },
      },
    });
    return response.data;
  },
);

export const getRelatedProduct = createAsyncThunk(
  'article/getRelatedProduct',
  async (id) => {
    const response = await API_PRODUCT_CATALOG({
      params: {
        filter: {
          _id: {
            $in: id,
          },
        },
        limit: 3,
      },
    });
    return response.data;
  },
);

export const getMustCheck = createAsyncThunk(
  'article/getMustCheck',
  async (id) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            _id: {
              $in: id,
            },
          },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at',
          limit: 6,
        },
      },
    });
    return response.data;
  },
);

export const getRelatedQuiz = createAsyncThunk(
  'article/getRelatedQuiz',
  async (id) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            post_type: 'pid_quiz',
            _id: {
              $in: id,
            },
          },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at',
          limit: 6,
          sort: '-published_at',
        },
      },
    });
    return response.data;
  },
);

export const postActionArticle = createAsyncThunk(
  'article/postActionArticle',
  async (payload) => {
    const response = await API_POST_BJ(
      payload.data,
      `/${payload.id}/${payload.type}`,
      'post',
    );
    return response.data;
  },
);

export const getCommentList = createAsyncThunk(
  'article/getCommentList',
  async (payload) => {
    const response = await API_POST_BJ(
      {
        params: {
          sort: '-created_at',
          limit: 10,
          skip: 0,
        },
      },
      `/${payload.id}/comments`,
      'get',
    );
    return response.data;
  },
);

export const postQuizAnswer = createAsyncThunk(
  'article/postQuizAnswer',
  async (payload) => {
    const response = await API_QUIZ_BJ(
      payload.data,
      `/${payload.type}/${payload.quizId}`,
    );
    return response.data;
  },
);

export const getPreviewArticleData = createAsyncThunk(
  'article/getPreviewArticleData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${payload.slug}`,
      payload: {
        params: {
          section: 'articleDetail',
          filter: {
            revision_id: payload.revision,
          },
        },
      },
    });
    return response.data;
  },
);

export const getPreviewQuizData = createAsyncThunk(
  'article/getPreviewQuizData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${payload.id}`,
      payload: {
        params: {
          section: 'quizDetail',
          filter: {
            revision_id: payload.revision,
          },
        },
      },
    });
    return response.data;
  },
);

export const getRelatedArticleData = createAsyncThunk(
  'article/getRelatedArticleData',
  async (slug) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${slug}/related`,
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at',
          limit: 4,
        },
      },
    });
    return response.data;
  },
);
