/* eslint-disable no-useless-escape */
export const vcRegex = /\[\/*vc.*?\]/gim;
export const stbRegex = /(\[stb_.*?\])/gim;
export const owlContainerRegex =
  /\[bjrv_owl.[\s\S]*?\/bjrv_owl_carousel_container]/gim;
export const owlSlideshowRegex =
  /\[bjrv_post.[\s\S]*?\/bjrv_post_slideshow\]/gim;
export const imgUrlRegex = /(http(s?):)([)(/|.|\w|\s|-])*/gim;
export const idRegex = /[0-9]{4}/gim;
export const numRegex = /[0-9]{1}/gim;
export const embedTagRegex = /\[\/*embed\]/gim;
export const iframeRegex = /(<iframe.*?>.*?<\/iframe>)/gim;
export const embedRegex =
  /(href=")?http(s)?:\/\/(www.)?(instagram|twitter|youtu|facebook|tiktok|x.com)([a-zA-Z0-9_.@/?=&"-]+)?/gim;
export const splitter =
  /(<((p|span|style)([^>]*)?>))?((-{39})\sSPLIT\sPAGE\s+\6)(<\/\3>)?/gim;
export const productSliderRGX = /(\[prod_sldr.*?\])/gim;
export const compareImageRGX = /(\[compare_sldr.*?\])/gim;
export const ulPattern = /<ul>(.*?)<\/ul>|<li>(.*?)<\/li>/gim;
export const liPattern = /<li[^>]*>(.*?)<\/li>/gim;
export const getValueRgx = /<\/?[^>]+(>|$)/gim;
export const iframeEncodedRegex = /(&lt;iframe.*?&gt;.*?&lt;\/iframe&gt;)/gim;
export const iframeSrcRegex = /(?:src=").*?(?=[\*"])/gim;
export const factRegex = /\[fact title="([^"]+)" text="([^"]+)"\]/gim;
export const allFactRegex = /\[facts.[\s\S]*?\/facts\]/gim;
export const pageRegex = /\?page=(all|\d+)/;
export const revisionRegex = /\brevision=([0-9a-zA-Z]+)\b/;
export const paragraphRegex = /<p[^>]*>(.*?)<\/p>/gim;
export const compareSliderRegex = /\[compare_sldr\s+img="([^"]+)"\s*\/?\]/gim;
export const columnRegex =
  /\[vc_row\]|\[\/vc_row\]|\[vc_column\]|\[\/vc_column\]|\[vc_column_text\]|\[\/vc_column_text\]|\[bjrv_post_featured_image[^\]]*\]/g;
