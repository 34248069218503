import { Platform, Dimensions } from 'react-native';
import { StyleSheet } from 'react-native';

const dimension = Dimensions.get('window');
const screenWidth = dimension.width;
const screenHeight = dimension.height;

let maxWidthContainer = 1247;

if (screenWidth <= 1240 && screenWidth >= 1084) {
  maxWidthContainer = 1024;
} else if (screenWidth < 1084 && screenWidth >= 991) {
  maxWidthContainer = 940;
} else if (screenWidth < 991) {
  maxWidthContainer = screenWidth;
}

const isWeb = Platform.OS == 'web' && screenWidth >= 991;
const isMobileWeb = Platform.OS == 'web' && screenWidth < 991;
const isWebPlatform = Platform.OS == 'web';
const isNative = Platform.OS == 'android' || Platform.OS == 'ios';
const fullWidth = screenWidth;
const fullHeight = screenHeight;

const CreateResponsiveStyle = (webStyles, mobileStyles) => {
  const web = StyleSheet.create(webStyles);
  const mobile = StyleSheet.create(mobileStyles);

  return () => (style) => {
    if (isMobileWeb && Object.prototype.hasOwnProperty.call(mobile, style)) {
      return StyleSheet.compose(web[style], mobile[style]);
    } else {
      return web[style];
    }
  };
};

export {
  maxWidthContainer,
  fullWidth,
  fullHeight,
  isWeb,
  isMobileWeb,
  isWebPlatform,
  isNative,
  screenWidth,
  screenHeight,
  CreateResponsiveStyle,
};
