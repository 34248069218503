import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BannerModal } from '@web-components';
import { selectModalStatus } from '~store/app/slice';

const BannerModalWrapper = (props) => {
  const { routeActive } = props;
  const isBannerModalOpen = useSelector(selectModalStatus('banner'));

  return (
    <BannerModal
      visible={isBannerModalOpen && !!routeActive}
      routeActive={routeActive}
    />
  );
};
BannerModalWrapper.propTypes = {
  routeActive: PropTypes.string,
};

export default BannerModalWrapper;
