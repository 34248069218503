import React from 'react';
import { Pressable, View } from 'react-native';
import { string, number, func, any, object } from 'prop-types';

import { CreateResponsiveStyle } from '~global-screens';
import { Colors } from '@web-styles';

const PaginationDot = ({
  data,
  activeIndex,
  activeColor,
  customStyle,
  inActiveColor,
  backgroundColor,
  handleChangeIndex = () => {},
}) => {
  const styles = componentStyles();

  return (
    <View
      style={[
        styles('paginationContainer'),
        { backgroundColor: backgroundColor },
      ]}>
      {data?.map((e, i) => (
        <Pressable
          key={`pagiDot-${i}`}
          onPress={() => handleChangeIndex(i + 1)}
          style={[
            styles('paginationDot'),
            customStyle,
            {
              backgroundColor:
                activeIndex === i + 1 ? activeColor : inActiveColor,
            },
          ]}
        />
      ))}
    </View>
  );
};

const webStyles = {
  paginationContainer: {
    flexDirection: 'row',
    marginTop: 40,
    alignItems: 'center',
    columnGap: 8,
    alignSelf: 'center',
  },
  paginationDot: {
    width: 10,
    height: 10,
    borderRadius: 20,
  },
};

const mobileStyles = {
  paginationContainer: {
    marginTop: 20,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

PaginationDot.defaultProps = {
  activeColor: Colors.CARNATION_PINK,
  inActiveColor: Colors.PLATINUM,
  customStyle: {
    marginBottom: 20,
  },
};

PaginationDot.propTypes = {
  handleChangeIndex: func,
  activeIndex: number,
  data: any,
  activeColor: string,
  inActiveColor: string,
  customStyle: object,
  backgroundColor: string,
};

export default PaginationDot;
