import React from 'react';
import { View } from 'react-native-web';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import PropTypes from 'prop-types';

import { ms, hs } from '@web-styles';
import { CreateResponsiveStyle, maxWidthContainer } from '~global-screens';

const PlaceHolderBase = ({ type }) => {
  const styles = componentStyles();

  if (type === 'banner') {
    return (
      <View style={styles('container')}>
        <Placeholder Animation={Fade}>
          <PlaceholderLine width={100} height={50} />
        </Placeholder>
      </View>
    );
  }

  return (
    <View style={styles('container')}>
      <Placeholder Animation={Fade}>
        <PlaceholderLine width={100} height={50} />
      </Placeholder>
    </View>
  );
};

const webStyles = {
  container: {
    width: maxWidthContainer,
    margin: 20,
    alignSelf: 'center',
  },
};

const mobileStyles = {
  container: {
    width: maxWidthContainer - hs(32),
    margin: ms(16),
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

PlaceHolderBase.propTypes = {
  type: PropTypes.string,
};

export default PlaceHolderBase;
