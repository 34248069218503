import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { Text, View, Pressable } from 'react-native';
import PropTypes from 'prop-types';

import { CreateResponsiveStyle, isWeb } from '~global-screens';
import { Fonts, Colors, vs, hs } from '@web-styles';

const SliderControler = ({ handleChangeIndex, activeIndex, data }) => {
  const styles = componentStyles();

  return (
    <View style={styles('sliderControlContainer')}>
      <View style={styles('sliderControl')}>
        <Pressable
          style={styles('btnContainer')}
          onPress={() => {
            handleChangeIndex('prev');
          }}>
          <View
            style={[
              styles('arrowContainer'),
              {
                backgroundColor:
                  activeIndex === 1
                    ? Colors.LIGHT_SILVER
                    : Colors.CARNATION_PINK,
              },
            ]}>
            <AntDesign
              name={'left'}
              size={isWeb ? 20 : 15}
              color={Colors.WHITE}
            />
          </View>
          <Text
            style={[
              Fonts('detailCommentTxt'),
              {
                color:
                  activeIndex === 1
                    ? Colors.LIGHT_SILVER
                    : Colors.CARNATION_PINK,
              },
            ]}>
            Prev
          </Text>
        </Pressable>
        <Text>{`${activeIndex} dari ${data?.length}`}</Text>
        <Pressable
          style={styles('btnContainer')}
          onPress={() => {
            handleChangeIndex('next');
          }}>
          <Text
            style={[
              Fonts('detailCommentTxt'),
              {
                color:
                  activeIndex === data?.length
                    ? Colors.LIGHT_SILVER
                    : Colors.CARNATION_PINK,
              },
            ]}>
            Next
          </Text>
          <View
            style={[
              styles('arrowContainer'),
              {
                backgroundColor:
                  activeIndex === data?.length
                    ? Colors.LIGHT_SILVER
                    : Colors.CARNATION_PINK,
              },
            ]}>
            <AntDesign
              name={'right'}
              size={isWeb ? 20 : 15}
              color={Colors.WHITE}
            />
          </View>
        </Pressable>
      </View>
    </View>
  );
};

const webStyles = {
  sliderControlContainer: {
    alignItems: 'center',
  },
  sliderControl: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 20,
    width: '70%',
  },
  btnContainer: {
    flexDirection: 'row',
    columnGap: 16,
    alignItems: 'center',
  },
  arrowContainer: {
    width: 30,
    height: 30,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const mobileStyles = {
  sliderControl: {
    marginTop: vs(16),
    marginBottom: vs(20),
  },
  arrowContainer: {
    width: hs(25),
    height: vs(25),
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

SliderControler.propTypes = {
  handleChangeIndex: PropTypes.func,
  activeIndex: PropTypes.number,
  data: PropTypes.any,
};

export default SliderControler;
