export const Fonts = [
  {
    'brandon-bold': require('~assets/fonts/brandon/brandontext-bold-webfont.woff2'),
  },
  {
    'brandon-light': require('~assets/fonts/brandon/brandontext-light-webfont.woff2'),
  },
  {
    'brandon-regular': require('~assets/fonts/brandon/brandontext-regular-webfont.woff2'),
  },
  {
    'lato-bold': require('~assets/fonts/lato/Lato-Bold.woff2'),
  },
  {
    'lato-italic': require('~assets/fonts/lato/Lato-Italic.woff2'),
  },
  {
    'lato-medium': require('~assets/fonts/lato/Lato-Medium.woff2'),
  },
  {
    'lato-regular': require('~assets/fonts/lato/Lato-Regular.woff2'),
  },
];
