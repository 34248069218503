import { createSlice } from '@reduxjs/toolkit';
import { postLogin, getLogout } from './authThunk';

import { storageName, removeStorage, saveStorage } from '@web-utils';

const initialState = {
  isAuthenticated: false,
  status: {
    postLoginFetch: false,
    postLoginError: null,
    getLogoutFetch: false,
    getLogoutError: null,
  },
  userData: null,
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated(state, action) {
      if (action.payload === true) {
        state.isAuthenticated = action.payload;
      } else {
        state.isAuthenticated = action.payload;
        state.userData = null;
        removeStorage(storageName.userToken);
        removeStorage(storageName.userData);
        window.dataLayer.push({
          event: 'loggedOut',
        });
      }
    },
    setUserData(state, action) {
      state.userData = JSON.parse(action.payload);
    },
    resetError(state) {
      state.status.postLoginError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.status.postLoginError = null;
        state.status.postLoginFetch = true;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.status.postLoginFetch = false;
      })
      .addCase(postLogin.fulfilled, (state, { payload }) => {
        if (payload.type === 'success') {
          const responseToSave = payload.data.data.user;
          delete responseToSave.clients;
          state.userData = responseToSave;
          saveStorage(storageName.userToken, payload.data.data.token);
          saveStorage(storageName.userData, responseToSave);
          state.isAuthenticated = true;
          state.status.postLoginFetch = false;
        } else {
          switch (payload.data.errorCode) {
            case 'USER_NOT_FOUND':
              state.status.postLoginError = 'User Not Found';
              break;
            case 'INCORRECT_PASSWORD':
              state.status.postLoginError = 'Wrong Password Entered';
              break;
            case 'ACCOUNT_LOCKED_FOR_TOO MANY_FAILED_ATTEMPTS!':
              state.status.postLoginError =
                'Your account is blocked because 5 times you failed to login. Please Reset Your Password to access the account';
              break;
            default:
              state.status.postLoginError = 'Network Error';
              break;
          }
          state.status.postLoginFetch = false;
        }
      })
      .addCase(getLogout.pending, (state) => {
        state.status.getLogoutError = null;
        state.status.getLogoutFetch = true;
      })
      .addCase(getLogout.rejected, (state, action) => {
        state.status.getLogoutFetch = false;
      })
      .addCase(getLogout.fulfilled, (state, { payload }) => {
        state.status.getLogoutFetch = false;
        state.isAuthenticated = false;
        state.userData = null;
        removeStorage(storageName.userToken);
        removeStorage(storageName.userData);
        window.dataLayer.push({
          event: 'loggedOut',
        });
      });
  },
});

export const { setAuthenticated, resetError, setUserData } = actions;

export const selectAuthenticated = (state) => state.auth.isAuthenticated;
export const selectFetchStatus = (type) => {
  return (state) => state.auth.status[type];
};
export const selectUserData = (state) => state.auth.userData;

export default reducer;
