import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { WebView } from 'react-native-webview';

import { CreateResponsiveStyle, maxWidthContainer } from '~global-screens';

const getTiktokIdFromURL = (url) => {
  const regExp = /\/video\/(\d+)/;
  const match = url.match(regExp);
  if (match && match[1]) {
    return match[1];
  }
  return '';
};

const customHTMLElementModels = {
  'tiktok-frame': HTMLElementModel.fromCustomModel({
    tagName: 'tiktok-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const parseTiktokData = (i, tiktokLink) => {
  const url = tiktokLink;
  let parsed = '';
  if (url && url.length) {
    parsed = `<tiktok-frame id=${url} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseTiktokData(i, listData[i]),
    );
  }

  return parsedContent;
};

const TiktokFrame = ({ tnode }) => {
  const IS_VIDEO_EXIST_HEIGHT = 760;
  const IS_VIDEO_MISSING_HEIGHT = 300;
  const styles = componentStyles();
  const [isVideoExist, setIsVideoExist] = useState(IS_VIDEO_EXIST_HEIGHT);

  useEffect(() => {
    (async () => {
      try {
        await fetch(`https://www.tiktok.com/oembed?url=${tnode.id}`, {
          method: 'HEAD',
        });
      } catch (error) {
        if (error instanceof TypeError) {
          setIsVideoExist(IS_VIDEO_MISSING_HEIGHT);
        } else if (error.response) {
          const statusCode = error.response.status;

          // rate-limit error
          if (statusCode === 429) {
            setIsVideoExist(IS_VIDEO_EXIST_HEIGHT);
          } else {
            setIsVideoExist(IS_VIDEO_MISSING_HEIGHT);
          }
        } else {
          setIsVideoExist(IS_VIDEO_MISSING_HEIGHT);
        }
      }
    })();
  }, [tnode.id]);

  return (
    <View>
      <WebView
        source={{
          uri: `https://www.tiktok.com/embed/${getTiktokIdFromURL(tnode.id)}`,
        }}
        style={[
          styles('containerWebView'),
          {
            height: isVideoExist,
          },
        ]}
      />
    </View>
  );
};

const webStyles = {
  containerWebView: {
    width: maxWidthContainer,
    alignSelf: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'tiktok-frame': TiktokFrame,
};

TiktokFrame.propTypes = {
  tnode: PropTypes.shape({
    // treat id as the video url
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
