import { useState, useEffect, useRef } from 'react';

const useAppReady = (mainDependency, secondDependency) => {
  const [layoutReady, setLayoutReady] = useState(false);
  const [appIsReady, setAppReady] = useState(false);
  const prerenderReadyRef = useRef(false); // Track previous value

  useEffect(() => {
    if (
      layoutReady &&
      mainDependency?.length > 0 &&
      secondDependency?.length > 0
    ) {
      if (!prerenderReadyRef.current) {
        window.prerenderReady = true;
        prerenderReadyRef.current = true; // Update the ref immediately after setting it

        // Push event to dataLayer
        window.dataLayer.push({
          event: 'pageNameReady',
        });
      }

      // Update app readiness state
      setAppReady(true);
    }
  }, [layoutReady, mainDependency, secondDependency]);

  const onLayout = (e) => {
    if (e.nativeEvent.layout) {
      setLayoutReady(true);
    }
  };

  return {
    appIsReady,
    onLayout,
  };
};

export default useAppReady;
