/* eslint-disable no-undef */
import React from 'react';
import { View, Image, Text, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';

import { CreateResponsiveStyle, screenWidth } from '~global-screens';
import { Colors } from '@web-styles';

import { toggleModalStatus } from '~store/app/slice';

import BaseModal from './BaseModal';

const NOT_FOUND_500 = require('~assets/images/notfound/500.svg');
const NOT_FOUND_503 = require('~assets/images/notfound/503.svg');
const LOGO_BJ = require('~assets/images/logo-bj.svg');

const NotFoundModal = ({ visible, type }) => {
  const dispatch = useDispatch();
  const styles = componentStyles();
  const navigation = useNavigation();

  const toggle = () => {
    dispatch(toggleModalStatus('notFoundModal', false));
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('HomeScreen');
    }
  };

  const Component_500 = () => {
    return (
      <View style={styles('contentContainer404')}>
        <Image source={LOGO_BJ} style={styles('logoBj')} />
        <Image source={NOT_FOUND_500} style={styles('notFound404Img')} />
        <View style={styles('textContainer404')}>
          <Text style={styles('mainText')}>Sorry Unexpected Error</Text>
          <Text style={styles('subText')}>
            We are working on fixing this problem, back online soon
          </Text>
          <Pressable onPress={toggle} style={styles('backBtn')}>
            <Text style={styles('textBtn')}>Reload</Text>
          </Pressable>
        </View>
      </View>
    );
  };

  const Component_503 = () => {
    return (
      <View style={styles('contentContainer503')}>
        <Image source={LOGO_BJ} style={styles('logoBj')} />
        <Image source={NOT_FOUND_503} style={styles('notFound503Img')} />
        <View style={styles('textContainer404')}>
          <Text style={styles('mainText')}>Under Maintenance</Text>
          <Text style={styles('subText')}>
            Our website currently undergoing scheduled maintenance, will be back
            in a few minutes.
          </Text>
          <Text style={styles('subText')}>Thank you for your patience.</Text>
        </View>
      </View>
    );
  };

  let RenderComponent = null;

  switch (type) {
    case '500':
      RenderComponent = <Component_500 />;
      break;
    case '503':
      RenderComponent = <Component_503 />;
      break;
    default:
      RenderComponent = <Component_500 />;
      break;
  }

  return (
    <BaseModal visible={visible} toggle={toggle}>
      <View style={styles('container')}>{RenderComponent}</View>
    </BaseModal>
  );
};
const webStyles = {
  container: {
    backgroundColor: Colors.WHITE,
    width: screenWidth,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer404: {
    width: 695,
    alignItems: 'center',
    rowGap: 64,
  },
  contentContainer503: {
    width: 715,
    alignItems: 'center',
    rowGap: 64,
  },
  logoBj: {
    width: 150,
    height: 50,
    resizeMode: 'contain',
  },
  notFound404Img: {
    width: 309,
    height: 300,
    resizeMode: 'contain',
  },
  notFound503Img: {
    width: 600,
    height: 300,
    resizeMode: 'contain',
  },
  textContainer404: {
    rowGap: 24,
    alignItems: 'center',
  },
  mainText: {
    fontFamily: 'Work Sans',
    fontSize: 32,
    fontWeight: 700,
    letterSpacing: 0.2,
  },
  subText: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.2,
    textAlign: 'center',
  },
  backBtn: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderWidth: 1,
    backgroundColor: Colors.CARNATION_PINK,
  },
  textBtn: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '26px',
  },
};

const mobileStyles = {
  container: {
    justifyContent: 'none',
    paddingTop: 76,
  },
  contentContainer404: {
    width: screenWidth - 32,
    rowGap: 32,
  },
  contentContainer503: {
    width: screenWidth - 32,
    rowGap: 32,
  },
  logoBj: {
    width: 120,
    height: 40,
  },
  notFound404Img: {
    width: 196,
    height: 190,
  },
  notFound503Img: {
    width: screenWidth - 32,
    height: 300,
  },
  textContainer404: {
    rowGap: 16,
  },
  mainText: {
    fontSize: 20,
  },
  subText: {
    fontSize: 14,
    lineHeight: '24px',
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

NotFoundModal.defaultProps = {
  type: '404',
};

NotFoundModal.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.string,
};

export default NotFoundModal;
