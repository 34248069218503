import numbro from 'numbro';
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Capitalizes all letters in a string.
 *
 * @param {string} string
 * @returns {string|undefined} Capitalized string or undefined if input is invalid.
 */
export const capitalizeLetter = (string) => {
  if (!string) {
    return undefined;
  }
  return string.toUpperCase();
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string
 * @returns {string|undefined} Capitalized string or undefined if input is invalid.
 */
export const capitalizeFirstLetter = (string) => {
  if (!string || string.charAt(0) === undefined) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Removes duplicates from an array based on a specified property.
 *
 * @param {Array} array
 * @param {string} type
 * @returns {Array} Array with duplicates removed.
 */
export const removeDuplicateArray = (array, type) => {
  if (array.length === 0) {
    return [];
  }
  return array.filter(
    (value, index, self) =>
      self.findIndex((item) => item[type] === value[type]) === index,
  );
};

/**
 * Removes HTML span tags from an input HTML string.
 *
 * @param {string} inputHtml
 * @returns {string} Text without span tags.
 */
export const removeSpanTags = (inputHtml) => {
  const doc = new DOMParser().parseFromString(inputHtml, 'text/html');
  return (doc.body.textContent || '').trim();
};

/**
 * Checks if a string includes "http".
 *
 * @param {string} str
 * @returns {boolean} True if the string includes "http", otherwise false.
 */
export const isIncludesHttp = (str) => {
  return str && str.includes('http');
};

/**
 * Removes HTML tags from an input HTML string.
 *
 * @param {string} htmlString
 * @returns {string} Text without HTML tags.
 */
export const removeHtmlTags = (htmlString) => {
  return htmlString?.replace(/<[^>]*>/g, '');
};

/**
 * Formats a number as currency.
 *
 * @param {number} value
 * @returns {string|number|undefined} Formatted currency string or original value if invalid.
 */
export const formatCurrency = (value) => {
  if (value == null) {
    return undefined;
  }
  try {
    return numbro(parseFloat(value)).format({
      thousandSeparated: true,
      mantissa: 0,
    });
  } catch (e) {
    return value;
  }
};

/**
 * Handles the push event for tracking view content and updates the dataLayer accordingly.
 *
 * @param {string} url - The URL from which to extract information for tracking.
 * @param {string} type - The type of content category.
 *
 * @returns {void} - No explicit return value. Updates the dataLayer with viewContent event data.
 */

export const handlePushEventViewContent = (url, type) => {
  const urlObject = new URL(url);
  const pathSegments = urlObject.pathname.split('/');
  if (pathSegments?.length) {
    const parent = pathSegments?.[1]?.replace(/-/g, ' ');
    const child = pathSegments?.[2]?.replace(/-/g, ' ');
    const data = {
      event: 'viewContent',
      bj_content: {
        category_type: type,
        category_parent:
          parent && typeof parent == 'string'
            ? parent.charAt(0).toUpperCase() + parent.slice(1)
            : '',
        category_child:
          child && typeof child == 'string'
            ? child.charAt(0).toUpperCase() + child.slice(1)
            : '',
      },
    };
    window.dataLayer.push(data);
  }
};

/**
 * Capitalizes the first letter of each word in a given sentence.
 *
 * @param {string} sentence - The input sentence to be capitalized.
 * @returns {string} - The sentence with the first letter of each word capitalized.
 */

export const capitalizeFirstAllLetterSentence = (sentence) => {
  if (!sentence) {
    return undefined;
  }
  const exceptions = ['dan', 'yang', 'to', 'and', 'di', 'dari', 'ini'];
  return sentence.replace(/\b\w+\b/g, function (word) {
    return exceptions.includes(word.toLowerCase())
      ? word
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};

/**
 * Limits the rate at which a function can be called by introducing a delay.
 *
 * @param {function} func - The function to be debounced.
 * @param {number} delay - The time delay (in milliseconds) before the function is invoked after the last invocation.
 * @returns {function} - A debounced version of the input function.
 */

export const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const listAlphabet = [
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const storageName = {
  userToken: 'SOCIOLLA_UID',
  userData: 'USER_DATA',
  guid: 'GUID',
};
/**
 * Saves data to the local storage.
 *
 * @param {string} key - The key under which the data will be stored.
 * @param {any} value - The data to be stored. Must be a JSON-serializable value.
 * @returns {any} - Returns the stored value if successful, or an empty string if the value is falsy.
 */
export const saveStorage = (key, value) => {
  if (value) {
    AsyncStorage.setItem(key, JSON.stringify(value));
    return value;
  }
  return '';
};

/**
 * Removes data from the local storage.
 *
 * @param {string} key - The key of the data to be removed.
 * @returns {undefined} - Returns undefined.
 */
export const removeStorage = (key) => {
  return AsyncStorage.removeItem(key);
};

/**
 * Retrieves data from the local storage.
 *
 * @param {string} key - The key of the data to be retrieved.
 * @returns {any} - Returns the parsed JSON value if successful, or the raw string if parsing fails.
 */
export const getStorage = (key) => {
  try {
    return JSON.parse(AsyncStorage.getItem(key));
  } catch (error) {
    return AsyncStorage.getItem(key);
  }
};

/**
 * Replace special characters with space in string
 *
 * @param {string} keyword - The keyword of which the characters will be replaced
 * @returns {string} - Modified keyword contains replaced caharacters with space
 */
export const replaceSpecialCharactersWithSpace = (keyword) => {
  return keyword?.replaceAll(/[^a-zA-Z0-9]+/g, ' ');
};
