/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Image } from 'react-native';
import { oneOfType, shape, string, any, bool, number } from 'prop-types';

import { isWeb } from '~global-screens';

const CdnImage = ({
  source,
  imageSize = isWeb ? 850 : 700,
  resizeMode,
  customImageSize,
  ...props
}) => {
  const [imageError, setImageError] = useState(false);
  const regex = /https:\/\/img\.youtube\.com\/vi\/[^/]+/;

  if (source?.uri && regex.test(source?.uri)) {
    return <Image source={source} {...props} role="presentation" />;
  }

  const formatImage = `${
    !imageError
      ? customImageSize
        ? 'w=' + customImageSize
        : 'w=' + imageSize
      : ''
  },format=auto,quality=100,dpr=3/`;

  const cdnUrl = source?.uri
    ? { uri: `https://www.soco.id/cdn-cgi/image/${formatImage}${source?.uri}` }
    : source;

  return (
    <Image
      source={imageError ? require('~assets/images/default-image.svg') : cdnUrl}
      {...props}
      onError={() => setImageError(true)}
      role="presentation"
      resizeMode={imageError ? 'contain' : resizeMode ? resizeMode : 'cover'}
    />
  );
};

CdnImage.propTypes = {
  source: oneOfType([
    any,
    shape({
      uri: string,
    }),
  ]),
  imageSize: oneOfType([bool, number]),
  resizeMode: string,
  customImageSize: number,
};

export default CdnImage;
