import React from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { iframeRegex } from './Regex.constant';
import { isWeb } from '~global-screens';
import { vs } from '@web-styles';

const getYoutubeIdFromURL = (url) => {
  const regExp =
    // eslint-disable-next-line no-useless-escape
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2]) {
    if (match[2].length === 11) {
      return match[2];
    } else {
      return match[2].substring(0, 11);
    }
  }

  return '';
};

const customHTMLElementModels = {
  'youtube-frame': HTMLElementModel.fromCustomModel({
    tagName: 'youtube-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;

  parsedContent = parsedContent.replace(iframeRegex, (match) => {
    const srcMatch = match.match(
      /src=["'](https?:\/\/(?:www\.)?youtube\.com\/embed\/([^"'?]+))[^"']*/i,
    );
    if (srcMatch && srcMatch[2]) {
      const videoId = srcMatch[2];
      return `<youtube-frame id="${videoId}" />`;
    }
    return match;
  });

  listData.forEach((url) => {
    const videoId = getYoutubeIdFromURL(url);
    if (videoId) {
      const escapedUrl = url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const urlRegex = new RegExp(escapedUrl, 'g');
      parsedContent = parsedContent.replace(
        urlRegex,
        `<youtube-frame id="${videoId}" />`,
      );
    }
  });

  return parsedContent;
};

const YoutubeFrame = ({ tnode }) => {
  return (
    <View>
      <ReactPlayer
        url={`https://www.youtube.com/embed/${tnode.id}?modestbranding=1`}
        height={isWeb ? 500 : vs(194)}
        width={'100%'}
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
        }}
      />
    </View>
  );
};

const renderers = {
  'youtube-frame': YoutubeFrame,
};

YoutubeFrame.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
