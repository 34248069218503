import React, { useState, useEffect } from 'react';
import { StyleSheet, Animated, Easing, Image, View } from 'react-native';
import PropTypes from 'prop-types';
import { fullHeight, isNative } from '~global-screens';

const LoadingOverlay = ({ show, full, size, single, customHeight }) => {
  const spinValue = new Animated.Value(0);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (show) {
      setShouldRender(true);
    } else {
      setShouldRender(false);
    }
  }, [show]);

  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 360,
      duration: 300000,
      easing: Easing.linear,
      useNativeDriver: isNative ? true : false,
    }),
  ).start();

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  if (!shouldRender) {
    return null;
  }

  return (
    <View style={styles.containerOverlay(full, single, customHeight)}>
      <Animated.View style={styles.containerLoading(spin)}>
        <Image
          source={require('~assets/images/loader.svg')}
          style={{
            width: size,
            height: size,
          }}
        />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerOverlay: (full, single, customHeight) => ({
    ...(!single && StyleSheet.absoluteFillObject),
    backgroundColor: full ? 'rgba(255, 255, 255, 0.7)' : 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    height: customHeight ?? fullHeight,
  }),
  containerLoading: (spin) => ({
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    transform: [{ rotate: spin }],
  }),
});

LoadingOverlay.defaultProps = {
  size: 50,
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  full: PropTypes.bool,
  size: PropTypes.number,
  single: PropTypes.bool,
  customHeight: PropTypes.number,
};

export default LoadingOverlay;
