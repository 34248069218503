import { useEffect } from 'react';

const useKeyboardListener = (callback, type) => {
  useEffect(() => {
    document.addEventListener(type, callback, false);
    return () => {
      document.removeEventListener(type, callback, false);
    };
  }, [callback, type]);
};

export default useKeyboardListener;
