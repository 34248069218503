/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState, useCallback } from 'react';
import { View, Image, Pressable, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';

import { CreateResponsiveStyle, screenWidth, isWeb } from '~global-screens';
import {
  toggleModalStatus,
  selectBannerData,
  setBannerSession,
  selectBannerSession,
} from '~store/app/slice';
import { getBanners } from '~store/app/thunks';

import BaseModal from './BaseModal';

const getCurrentState = (state) => {
  if (state?.routes?.length) {
    const index = state.index || 0;
    if (state.routes[index].state) {
      return getCurrentState(state.routes[index].state);
    } else {
      return state.routes[index];
    }
  }
  return {};
};

const BannerModal = ({ visible, routeActive }) => {
  const dispatch = useDispatch();
  const styles = componentStyles();
  const bannerData = useSelector(selectBannerData);
  const bannerSession = useSelector(selectBannerSession);
  const navigation = useNavigation();

  const [bannersLoaded, setBannersLoaded] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(null);

  const getCurrentBanner = useCallback((routeName, bannerList) => {
    if (!bannerList) {
      return null;
    }

    const locationHash = {
      HomeScreen: 'homepage',
      ArticleDetailScreen: 'article-pages',
      QuizScreen: 'quiz-pages',
      SearchScreen: 'category-pages',
    };

    return bannerList.find(
      (banner) =>
        banner.type === 'pop-up-banner' &&
        [locationHash[routeName], 'all-pages'].includes(banner.location),
    );
  }, []);

  // Initial banner load
  useEffect(() => {
    if (!routeActive || bannersLoaded) {
      return;
    }

    setBannersLoaded(true);
    dispatch(getBanners()).then((bannerList) => {
      const banner = getCurrentBanner(routeActive, bannerList.payload.data);
      setCurrentBanner(banner);
      dispatch(toggleModalStatus('banner', !!banner));
    });
  }, [routeActive, bannersLoaded, dispatch, getCurrentBanner]);

  // Handle navigation changes
  useEffect(() => {
    if (!bannersLoaded) {
      return;
    }

    const unsubscribe = navigation.addListener('state', (e) => {
      const currentRoute = getCurrentState(e.data.state);
      const currentSession =
        currentRoute.name === 'ArticleDetailScreen'
          ? `${currentRoute.name}-${currentRoute.params?.slug}`
          : currentRoute.name;

      if (bannerSession === currentSession) {
        dispatch(toggleModalStatus('banner', false));
        return;
      }

      const banner = getCurrentBanner(currentRoute.name, bannerData);
      setCurrentBanner(banner);

      dispatch(toggleModalStatus('banner', !!banner));
    });

    return unsubscribe;
  }, [
    navigation,
    bannersLoaded,
    bannerData,
    bannerSession,
    getCurrentBanner,
    dispatch,
  ]);

  const toggle = useCallback(() => {
    dispatch(toggleModalStatus('banner', false));
    const sessionKey =
      routeActive === 'ArticleDetailScreen'
        ? `${routeActive}-${navigation.getCurrentRoute()?.params?.slug}`
        : routeActive;
    dispatch(setBannerSession(sessionKey));
  }, [dispatch, routeActive, navigation]);

  const imageSource = isWeb
    ? currentBanner?.desktop_image
    : currentBanner?.mobile_image;
  const aspectRatio = isWeb ? 1.75 : 300 / 533;
  const width = isWeb ? 900 / 1.45 : screenWidth * 0.65;

  return (
    <BaseModal visible={visible} toggle={toggle}>
      <View>
        <Pressable
          onPress={() => {
            Linking.openURL(currentBanner.link);
          }}>
          <Pressable onPress={toggle} style={styles('closeBtn')}>
            <AntDesign
              name="closecircle"
              size={isWeb ? 24 : 16}
              color="black"
            />
          </Pressable>
          <Image
            source={imageSource}
            style={{
              aspectRatio,
              width,
              position: 'relative',
            }}
            role="presentation"
            alt={'banner'}
          />
        </Pressable>
      </View>
    </BaseModal>
  );
};

const webStyles = {
  closeBtn: {
    position: 'absolute',
    top: isWeb ? -15 : '-1vh',
    right: isWeb ? -15 : -10,
    zIndex: 999,
    backgroundColor: 'white',
    borderRadius: 50,
    width: isWeb ? 24 : 16,
    height: isWeb ? 24 : 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

BannerModal.propTypes = {
  visible: PropTypes.bool,
  routeActive: PropTypes.string,
};

export default BannerModal;
