import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_AUTH_SOCO } from '~global-api/soco.api';
import md5 from 'md5';

const gtmInitialize = async (dataUser) => {
  try {
    if (dataUser) {
      const {
        my_sql_id: userID = '',
        _id: userIDMongo = '',
        email: userEmail = '',
        user_name: userName = '',
        first_name: userFirstname = '',
        last_name: userLastname = '',
        date_of_birth: userBirth = '',
        gender: userGender = '',
        phone_no: userPhone = '',
      } = dataUser;
      const hashedEmail = md5(userEmail);
      const data = {
        event: 'loggedIn',
        user_id: userID,
        user_id_mongo: userIDMongo,
        user_data: {
          userID,
          userIDMongo,
          userEmail,
          hashedEmail,
          userName,
          userFirstname,
          userLastname,
          userBirth,
          userGender,
          userPhone,
        },
      };
      window.dataLayer.push(data);
    }
  } catch (err) {
    console.log(err);
  }
};

export const postLogin = createAsyncThunk('auth/postLogin', async (payload) => {
  try {
    const response = await API_AUTH_SOCO('post', '/login', {
      ...payload,
      source: 'soco-web-desktop',
    });
    if (response.data.data.user) {
      gtmInitialize(response.data.data.user);
    }

    return {
      data: response.data,
      type: 'success',
    };
  } catch (error) {
    return {
      data: error?.response.data,
      type: 'error',
    };
  }
});

export const getLogout = createAsyncThunk('auth/getLogout', async () => {
  try {
    const response = await API_AUTH_SOCO('get', '/logout');
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
