/* eslint-disable quotes */
const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  let output = '';
  for (const i in listData) {
    if (i == 0 && listData[0] && listData[0].trim()) {
      if (listData[0].indexOf('<div class="content-filtered">') > -1) {
        let firstP = listData[0].trim();
        firstP = firstP.replace(
          '<div class="content-filtered">',
          '<div class="content-filtered"><p>',
        );
        output += `${firstP}</p>`;
      } else if (listData[0].trim().charAt(0) != '<') {
        output += `<p>${listData[0].trim()}</p>`;
      } else {
        output += listData[0].trim();
      }
    } else {
      if (
        listData[i] &&
        listData[i].trim() &&
        listData[i].trim().charAt(0) != '<' &&
        listData[i].indexOf('<li>') < 0
      ) {
        output += `<p>${listData[i].trim()}</p>`;
      } else if (listData[i].indexOf('</span>') > -1) {
        output += `<p>${listData[i].trim()}</p>`;
      } else {
        output += listData[i].trim();
      }
    }
  }
  output = output.replace(/<p><br><\/p>/g, '');
  output = output.replace(/<p>\n/g, '');
  output = output.replace(/\n<\/p>/g, '');
  output = output.replace(/<p><\/p>/g, '');
  output = output.replace(/<p><div/g, '<div');
  output = output.replace(/<\/div><\/p>/g, '</div>');
  output = output.replace(/<p>\[caption-img/g, '[caption-img');
  output = output.replace(/\[\/caption-img\]/g, '[/caption-img]<p>');
  output = output.replace(/<p style="text-align: center;"><br><\/p>/g, '');
  parsedContent = output;
  return parsedContent;
};

export default { parseHtmlData };
