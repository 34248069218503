import React from 'react';
import { View, Text } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';

import { liPattern, getValueRgx } from './Regex.constant';

const getValueFromList = (data) => {
  const listArr = [];
  for (let i = 0; i < data.length; i++) {
    listArr.push(data[i].replace(getValueRgx, '').replace('&nbsp;', ''));
  }
  return listArr;
};

const customHTMLElementModels = {
  'list-render': HTMLElementModel.fromCustomModel({
    tagName: 'list-render',
    contentModel: HTMLContentModel.block,
  }),
};

const parseListData = (i, listData) => {
  const match = listData.match(liPattern);
  const res = getValueFromList(match);
  let parsed = '';
  if (res && res.length) {
    parsed = `${res.map((e) => `<list-render  id="${e}"/>`)}`;
  }

  return parsed.replaceAll('>,<', '>&nbsp;<');
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;

  for (let i = 0; i < listData.length; i++) {
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseListData(i, listData[i]),
    );
  }
  return parsedContent;
};

const ListRender = ({ tnode }) => {
  return (
    <View>
      <View />
      <Text>{tnode.id}</Text>
    </View>
  );
};

const renderers = {
  'list-render': ListRender,
};

ListRender.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
