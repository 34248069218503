import React from 'react';
import { View } from 'react-native';
import Modal from 'react-native-modal';
import PropTypes from 'prop-types';
import Toast from 'react-native-toast-message';
import { toastConfig } from '~config/toast.config.js';
import { useSelector } from 'react-redux';

import { CreateResponsiveStyle } from '~global-screens';
import { isWeb } from '~global-screens';

import { selectModalStatus } from '~store/app/slice';

const toastProps = {
  visibilityTime: 4000,
  autoHide: true,
  position: 'top',
  topOffset: isWeb ? 10 : 20,
};

const BaseModal = ({ animation = 'fade', visible, children, toggle }) => {
  const styles = componentStyles();
  const isLoginModalOpen = useSelector(selectModalStatus('loginModal'));

  return (
    <Modal
      onBackdropPress={toggle}
      onBackButtonPress={toggle}
      onSwipeComplete={toggle}
      hasBackdrop
      backdropTransitionOutTiming={0}
      animationType={animation}
      isVisible={visible}
      style={styles('container')}>
      <View>
        <View>{children}</View>
        {isLoginModalOpen && <Toast {...toastProps} config={toastConfig} />}
      </View>
    </Modal>
  );
};
const webStyles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

BaseModal.propTypes = {
  animation: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.any,
  toggle: PropTypes.func,
};

export default BaseModal;
