import * as Colors from './Colors';
import { General, Section, Slides, Button, ScreenLayout } from './Layout';
import { Fonts } from './Fonts';
import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

const guidelineBaseWidth = 375;
const guidelineBaseHeight = 812;

const hs = (size) => (width / guidelineBaseWidth) * size;
const vs = (size) => (height / guidelineBaseHeight) * size;
const ms = (size, factor = 0.5) => size + (hs(size) - size) * factor;

export {
  Colors,
  General,
  Section,
  Slides,
  Button,
  ScreenLayout,
  Fonts,
  hs,
  vs,
  ms,
};
